import {$fetch} from 'ofetch';
import type {FetchOptions} from "ofetch";
import {defineNuxtPlugin, useRuntimeConfig} from "#imports";

import AuthModule from '~/repository/modules/auth';

interface IApiInstance {
	auth: AuthModule;
}

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();

	const fetchOptions: FetchOptions = {
		baseURL: config.public.baseURL + '/api/v1/'
	};

	// Create a new instance of $fecther with custom option
	const apiFecther = $fetch.create(fetchOptions);
 
	// An object containing all repositories we need to expose
	const modules: IApiInstance = {
		auth: new AuthModule(apiFecther),
	};

	return {
		provide: {
			api: modules
		}
	};
});

// 3rd's
import type {FetchOptions} from 'ofetch';
import {useAsyncData, useCookie} from '#app';
// locals
import FetchFactory from '../factory';
import type {IUseAsyncData} from "~/interfaces/utils/IUseAsyncData";

class AuthModule extends FetchFactory<any> {
	private LOGIN = '/login';
	private LOGOUT = '/logout';
	private GETME = '/me';

	async postLogin(body: any) {
		return useAsyncData<any, IUseAsyncData>(
			() => {
				const fetchOptions: FetchOptions<'json'> = {
					headers: {
						'Content-Type': 'application/json'
					}
				};
				return this.call('POST', `${this.LOGIN}`, body, fetchOptions);
			}
		)
	}
 
	async getLogout() {
		const token = useCookie('_token');
		return useAsyncData(
			() => {
				const fetchOptions: FetchOptions<'json'> = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token.value}`,
					}
				};
				return this.call('GET', `${this.LOGOUT}`, undefined, fetchOptions);
			}
		)
	}

	async getMe() {
		const token = useCookie('_token');
		return useAsyncData(
			() => {
				const fetchOptions: FetchOptions<'json'> = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token.value}`,
					}
				};
				return this.call('GET', `${this.GETME}`, undefined, fetchOptions);
			}
		)
	}
}

export default AuthModule;

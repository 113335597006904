import { default as adminsXVqzHHf1MiMeta } from "/home/corestagingharrisdeveloperseu/core.staging.harrisdevelopers.eu/src/pages/admins.vue?macro=true";
import { default as dashboardj3Kk3VPLiSMeta } from "/home/corestagingharrisdeveloperseu/core.staging.harrisdevelopers.eu/src/pages/dashboard.vue?macro=true";
import { default as indexyxWpTKKskxMeta } from "/home/corestagingharrisdeveloperseu/core.staging.harrisdevelopers.eu/src/pages/index.vue?macro=true";
import { default as loginZTpW45S3MLMeta } from "/home/corestagingharrisdeveloperseu/core.staging.harrisdevelopers.eu/src/pages/login.vue?macro=true";
export default [
  {
    name: adminsXVqzHHf1MiMeta?.name ?? "admins",
    path: adminsXVqzHHf1MiMeta?.path ?? "/admins",
    meta: adminsXVqzHHf1MiMeta || {},
    alias: adminsXVqzHHf1MiMeta?.alias || [],
    redirect: adminsXVqzHHf1MiMeta?.redirect,
    component: () => import("/home/corestagingharrisdeveloperseu/core.staging.harrisdevelopers.eu/src/pages/admins.vue").then(m => m.default || m)
  },
  {
    name: dashboardj3Kk3VPLiSMeta?.name ?? "dashboard",
    path: dashboardj3Kk3VPLiSMeta?.path ?? "/dashboard",
    meta: dashboardj3Kk3VPLiSMeta || {},
    alias: dashboardj3Kk3VPLiSMeta?.alias || [],
    redirect: dashboardj3Kk3VPLiSMeta?.redirect,
    component: () => import("/home/corestagingharrisdeveloperseu/core.staging.harrisdevelopers.eu/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexyxWpTKKskxMeta?.name ?? "index",
    path: indexyxWpTKKskxMeta?.path ?? "/",
    meta: indexyxWpTKKskxMeta || {},
    alias: indexyxWpTKKskxMeta?.alias || [],
    redirect: indexyxWpTKKskxMeta?.redirect,
    component: () => import("/home/corestagingharrisdeveloperseu/core.staging.harrisdevelopers.eu/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginZTpW45S3MLMeta?.name ?? "login",
    path: loginZTpW45S3MLMeta?.path ?? "/login",
    meta: loginZTpW45S3MLMeta || {},
    alias: loginZTpW45S3MLMeta?.alias || [],
    redirect: loginZTpW45S3MLMeta?.redirect,
    component: () => import("/home/corestagingharrisdeveloperseu/core.staging.harrisdevelopers.eu/src/pages/login.vue").then(m => m.default || m)
  }
]
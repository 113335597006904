import mitt from 'mitt';
import {defineNuxtPlugin} from "#imports";

export default defineNuxtPlugin(() => {
	const emitter = mitt();
	return {
		provide: {
			globalBusEmitter: {
				$on: emitter.on,
				$off: emitter.off,
				$emit: emitter.emit,
			},
		},
	};
});


import {defineNuxtRouteMiddleware, useCookie, navigateTo} from "#imports";

export default defineNuxtRouteMiddleware((to: any, from: any) => {
	const bearerToken = useCookie<string>('_token');
	let hasToken: boolean = bearerToken.value !== undefined;

	let noAuthRoutes: string[] = [
		'/login',
		'/invite'
	];

	if (hasToken && noAuthRoutes.includes(to.fullPath)) {
		return navigateTo('/');
	} else if (!hasToken && to.fullPath !== '/login') {
		return navigateTo('/login');
	}
})
